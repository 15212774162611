<template>
  <v-progress-circular indeterminate :rotate="360" :size="64" :width="8" color="success"
    v-if="loading"></v-progress-circular>
  <div v-else>
    <div class="d-flex align-center">
      <v-progress-circular :rotate="360" :size="64" :width="8" :value="pctPagado" color="success">
        <h4>{{ pctPagado }} %</h4>
      </v-progress-circular>
      <h4 class="ml-2">Pagado</h4>

      <v-spacer></v-spacer>

      <v-chip v-if="data?.financiado" label outlined color="info" class="font-weight-bold">
        <v-icon left>mdi-handshake</v-icon>
        Financiado
      </v-chip>

      <v-spacer></v-spacer>

      <v-btn color="primary" small @click="getPagos" class="mr-2">
        <v-icon left>mdi-refresh</v-icon>
        Recargar
      </v-btn>

      <v-btn v-if="!editMode" color="primary" small @click.stop="editMode = !editMode">
        <v-icon left small>mdi-pencil</v-icon>
        editar
      </v-btn>
      <template v-else>
        <v-btn color="error" small @click.stop="resetPagos">
          <v-icon left small>mdi-close</v-icon>
          Cancelar
        </v-btn>
        <v-btn class="ml-2" color="primary" small @click.stop="savePagos">
          <v-icon left small>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </template>
    </div>
    <v-simple-table class="mt-2">
      <thead>
        <tr>
          <th>B.Imponible</th>
          <th>Porcentaje</th>
          <th>% IVA</th>
          <th>Total Pago</th>
          <th>Fecha Pago</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr :key="p.idPago" v-for="(p, index) in pagos">
          <!-- <td>{{ money.format(p.baseImponible) }}</td> -->
          <td v-if="!editMode || p.pagado">{{ money.format(p.baseImponible) }}</td>
          <td v-else>
            <v-text-field suffix="€" v-model="p.baseImponible" hide-details dense outlined
              @change="dividirPagos('baseImponible')"></v-text-field>
          </td>

          <td v-if="!editMode || p.pagado">{{ pct.format(p.pct) }}</td>
          <td v-else>
            <v-text-field suffix="%" v-model="p.pct" hide-details dense outlined
              @change="dividirPagos('pct')"></v-text-field>
          </td>
          <td v-if="!editMode || p.pagado">{{ pct.format(p.iva) }}</td>
          <td v-else>
            <v-text-field suffix="%" v-model="p.iva" hide-details dense outlined
              @change="dividirPagos('pct')"></v-text-field>
          </td>
          <td>{{ money.format(p.totalPago) }}</td>
          <td>{{ parseDate(p.fechaPago) }}</td>
          <td>
            <v-btn @click.stop="confirmarPago = { show: true, idPago: p.idPago }" :disabled="editMode" v-if="!p.pagado"
              color="success" small>Marcar pagado</v-btn>
            <template v-if="p.pagado">
              <v-chip label outlined color="success" class="font-weight-bold text-button">
                <v-icon left>mdi-check</v-icon>
                Pagado
              </v-chip>
              <v-btn class="ml-2" fab x-small rounded color="primary" @click.stop="descargarDocumento(p)">
                <v-icon>mdi-file-document-outline</v-icon>
              </v-btn>
              <v-btn class="ml-2" fab x-small rounded color="error darken-2" @click.stop="desmarcarPagado(p)">
                <v-icon>mdi-cash-refund</v-icon>
              </v-btn>
            </template>
            <!-- <v-btn v-else color="success" small >Pagado</v-btn> -->

            <v-btn icon color="error" @click.stop="pagos.splice(index, 1)"
              v-if="editMode && !p.pagado"><v-icon>mdi-delete</v-icon></v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="d-flex mt-4" v-if="editMode">
      <v-alert text color="error" dense class="mb-0" v-if="totalPct != 100">Suma total de los porcentajes {{ totalPct }}
        %</v-alert>
      <v-spacer></v-spacer>
      <v-btn @click.stop="pagos.push({ pct: 0, iva: 0.21, pagado: 0 })" fab small
        color="primary"><v-icon>mdi-plus</v-icon></v-btn>
    </div>
    <!-- <ConfirmDialog ref="confirm" /> -->

    <v-dialog v-model="confirmarPago.show" max-width="500">
      <v-card>
        <v-form ref="confirmPago" @submit.prevent="marcarPagado">
          <v-toolbar dark dense color="success">
            <v-toolbar-title>Confirmar pago</v-toolbar-title>
          </v-toolbar>
          <div class="pa-4">

            <v-text-field label="Importe recibido" v-model="confirmarPago.importe" :rules="[rules.req]"
              hide-details="auto" outlined class="mb-2" suffix="€"></v-text-field>

            <v-date-field label="Fecha del pago" v-model="confirmarPago.date" :rules="[rules.req]"
              hide-details="auto"></v-date-field>

            <v-subheader class="mt-4">Justificante de pago</v-subheader>
            <DragAndDrop v-model="confirmarPago.file" :multiple="false" :rules="[rules.req]" hide-details="auto" />

          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmarPago = { show: false }">Cancelar</v-btn>
            <v-btn type="submit" color="success">Confirmar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { timeAgo, parseDate, downloadFile } from "@/utils";
import { req } from "@/utils/validations.js";

export default {
  components: {
    VDateField: () => import("@/components/VDateField.vue"),
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  props: {
    idEstudio: Number,
    tipo: String,
  },
  data() {
    return {
      loading: false,
      data: {},
      pagos: [],
      today: new Date(),
      money: Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }),
      pct: Intl.NumberFormat("de-DE", { style: "percent" }),
      editMode: false,
      confirmarPago: {
        show: false,
        idPago: null,
        file: null,
        date: null,
        importe: null,
      },
      rules: { req },
    };
  },
  computed: {
    totalPct() {
      return this.pagos.reduce((t, c) => t + Number(c.pct), 0) * 100;
    },
    pctPagado() {
      return Math.round(
        this.pagos
          .filter((p) => p.pagado)
          .reduce((t, c) => t + Number(c.pct), 0) * 100
      );
    },
  },
  mounted() {
    this.getPagos();
    this.getData();
  },
  methods: {
    timeAgo,
    parseDate,
    resetPagos() {
      this.pagos = [];
      this.getPagos();
      this.editMode = false;
    },
    async savePagos() {
      this.loading = true
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/pagos/${this.tipo}/${this.idEstudio}`,
        data: this.pagos.filter((p) => !p.pagado),
      });
      this.getPagos();
      this.editMode = false;
    },
    async marcarPagado() {
      if (!this.$refs.confirmPago.validate()) return;
      if (!this.confirmarPago.file) return;
      // const motivo = await this.$refs.confirm.open(
      //   "Confirmar pago",
      //   `¿Estás seguro de que quieres confirmar el pago de ${ this.money.format(pago.totalPago) } [${this.pct.format(pago.pct)}] ?`,
      //   { confirmColor: "success", width: 600 }
      // );
      // if (!motivo) return;
      try {
        const formData = new FormData();
        formData.append("file", this.confirmarPago.file[0]);
        formData.append("fechaPago", this.confirmarPago.date);
        formData.append("importe", this.confirmarPago.importe);

        const { data } = await axios({
          method: "PATCH",
          url: `${process.env.VUE_APP_API_URL}/pagos/pagar/${this.tipo}/${this.confirmarPago.idPago}`,
          data: formData,
        });
      } catch (err) {
        console.error(err);
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al confirmar el pago",
          "error"
        );
      } finally {
        this.confirmarPago = { show: false };
        this.getPagos();
      }
    },
    async getPagos() {
      if (!this.idEstudio) return;
      this.loading = true;
      this.pagos = [];
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/pagos/${this.tipo}/${this.idEstudio}`,
      });
      this.pagos = data;
      this.loading = false;
    },
    async getData() {
      if (!this.idEstudio) return;
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/${this.tipo}/${this.idEstudio}`,
      });
      this.data = data;
    },
    dividirPagos(key) {
      const importe = this.data?.precioVenta;
      if (key == 'pct') {
        this.pagos = this.pagos.map((p) => {
          if (p.pct > 1) p.pct /= 100;
          if (p.iva > 1) p.iva /= 100;
          p.pct = Number(p.pct);
          p.iva = Number(p.iva);
          return {
            ...p,
            baseImponible: importe * p.pct,
            pct: p.pct,
            iva: p.iva,
            totalPago: importe * p.pct * (1 + p.iva),
          };
        });
      } else if (key == 'baseImponible') {
        this.pagos = this.pagos.map((p) => {
          if (p.pct > 1) p.pct /= 100;
          if (p.iva > 1) p.iva /= 100;
          p.pct = Number(p.pct);
          p.iva = Number(p.iva);
          return {
            ...p,
            baseImponible: p.baseImponible,
            pct: p.baseImponible / importe,
            iva: p.iva,
            totalPago: p.baseImponible * (1 + p.iva),
          };
        })
      }
    },
    async descargarDocumento({ archivo_justificante: filename }) {
      const { data } = await axios({
        method: "GET",
        responseType: "blob",
        url: `${process.env.VUE_APP_API_URL}/pagos/${this.idEstudio}/${filename}`,
      });

      const file = new File([data], filename, {
        type: "application/pdf",
      });

      downloadFile(file, open);
    },
    async desmarcarPagado(pago) {
      const { data } = await axios({
        method: "PATCH",
        url: `${process.env.VUE_APP_API_URL}/pagos/impagar/${this.tipo}/${pago.idPago}`,
      });

      this.getPagos()
    }
  },
};
</script>
